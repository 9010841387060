@media screen {
  .print-report {
    thead,
    tfoot {
      display: none;
    }
  }
}

.yellow-bg {
  background: theme-color('darkyellow');
  color: theme-color('white');
}

.blue-bg {
  background: theme-color('secondary');
  color: theme-color('white');
}
.primary-bg {
  background: theme-color('primary');
  color: theme-color('white');
}
.print-header {
  color: $white;
  position: relative;
  z-index: 2;
}

.user-answers {
  .happy {
    color: theme-color('black');
    position: relative;
    padding: 10px 10px 10px 40px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 22px;
      width: 24px;
      height: 24px;
      background-color: theme-color('happy');
      border-radius: 24px;
      border: 2px solid theme-color('lightgrey');
    }

    .user-comments {
      border: 2px solid theme-color('happy');
      padding: 10px;
      border-radius: 10px;
      min-height: 45px;
    }
  }

  .sad {
    color: theme-color('black');
    position: relative;
    padding: 10px 10px 10px 40px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 22px;
      width: 24px;
      height: 24px;
      background-color: theme-color('sad');
      border-radius: 24px;
      border: 2px solid theme-color('lightgrey');
    }

    .user-comments {
      border: 2px solid theme-color('sad');
      padding: 10px;
      border-radius: 10px;
      min-height: 45px;
    }
  }

  .meh {
    color: theme-color('black');
    position: relative;
    padding: 10px 10px 10px 40px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 22px;
      width: 24px;
      height: 24px;
      background-color: theme-color('meh');
      border-radius: 24px;
      border: 2px solid theme-color('lightgrey');
    }

    .user-comments {
      border: 2px solid theme-color('meh');
      padding: 10px;
      border-radius: 10px;
      min-height: 45px;
    }
  }

  .gcyes {
    color: theme-color('lightgrey');
    position: relative;
    padding: 10px 10px 10px 40px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 22px;
      width: 24px;
      height: 24px;
      background-color: theme-color('lightgrey');
      border-radius: 24px;
      // border: 2px solid theme-color('lightgrey');
    }

    .user-comments {
      border: 2px solid theme-color('lightgrey');
      padding: 10px;
      border-radius: 10px;
      min-height: 45px;
    }
  }

  .gcno {
    color: #ccc;
    position: relative;
    padding: 10px 10px 10px 40px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 22px;
      width: 24px;
      height: 24px;
      background-color: #ccc;
      border-radius: 24px;
      // border: 2px solid theme-color('lightgrey');
    }

    .user-comments {
      border: 2px solid #ccc;
      padding: 10px;
      border-radius: 10px;
      min-height: 45px;
    }
  }

}
.chart-content {
  display: flex;
  flex-direction: row;
}
.chart-container {
  width: 100%;
  height: 600px;
  position: relative;
  // height:40vh;
  // width:30vw;
  margin: 0 auto;

  canvas {
    // width: 800px !important;
    // height: 400px !important;
    position: absolute;
  }
}

.print-report {
  table,
  .table {
    font-size: 14px;

    tr,
    td {
      padding: 0;
      border: 0;
    }
  }
}

.print-report {
  section {
    max-width: 29.7cm;
    margin: 0 auto;
    box-shadow: 0 0 5px #ccc;

    &.otherpages {
      padding: 0 1cm 1cm;
    }
  }
}
@media print {
  @page {
    margin: 1cm 0;
    position: relative;
  }
  .print-report {
    section {
      width: 29.7cm;
    }
  }
  .coverpage {
    page: coverpage;
    position: relative;
    width: 100%;
    height: 100%;
    // background: red;
    tfoot {
      // display: table-footer-group;
      position: absolute;
      bottom: 0;
      // width: 100%;
      left: 0;
      right: auto;
      z-index: 1;
      tr,
      td {
        padding: 0;
        div {
          background-color: theme-color('white');
          padding: 20px 40px;
          font-size: 18px;
          // width: 120vw;
          // background:red;
          border-bottom: 0 none;
          color: theme-color('grey');
          display: block;
        }
      }
    }
  }
  @page coverpage {
    margin: 0; /* Use a full size page */

    /* Remove page numbers for the cover */
    @bottom-center {
      content: none;
    }
  }
  @page {
    @bottom-center {
      content: 'Page ' counter(page) ' of ' counter(pages);
      position: relative;
      z-index: 1000;
    }
  }
  body {
    margin: 0;
    font-size: 30px;
  }
  .otherpages {
    padding: 1cm;
    position: relative;
    width: 90%;
    height: 100%;
    margin: 0 auto;
    table,
    .table {
      font-size: 20px;

      tr,
      td {
        padding: 0;
        border: 0;
      }

      thead {
        display: table-header-group;
        tr,
        td {
          padding: 0;
          div {
            padding: 30px 0 20px;
            // width: 110vw;
            // background:red;
            border-bottom: 2px solid theme-color('grey');
            display: block;
          }
        }
      }

      tfoot {
        display: none;
        position: fixed;
        top: 0;
        // width: 100%;
        left: auto;
        right: 0;
        z-index: 1;
        
        tr,
        td {
          padding: 0;
          div {
            background-color: theme-color('white');
            padding: 20px 40px;
            // width: 120vw;
            // background:red;
            border-bottom: 0 none;
            color: theme-color('grey');
            display: block;
          }
        }
      }
    }
  }
  .question h4,
  .question .user-answers .list,
  .user-comments{
    page-break-inside: avoid;
  }
  // .chart-content {
  //   display: block;
  //   flex-direction: column;
  //   .chart-container {
  //     // border:10px solid green;
  //     width: 100%;
  //     height: 700px;
  //     padding: 30px;
  //     canvas {
  //       // width: 800px !important;
  //       // height: 400px !important;
  //       position: static;
  //       margin: 0 auto;
  //     }
  //   }
  // }
}

.metadata-project {
  font-size: 24px;
}
