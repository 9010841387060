.cardBox {
    border: none;
    padding: 20px 30px;

    .card-body {
        padding: 0;
    }
}

.card-header,
.modal-header {
    padding: 0 0 25px 0;
    border-bottom-color: #e4e4e4;
    margin-bottom: 25px;

    h2,
    p {
        color: theme-color("primary");
        font-size: 1.5rem;
        font-weight: 500;

        span {
            font-size: 18px;
            font-weight: 400;
        }
    }
}

/*Survey Modal*/
.addedQuestionBox {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    .projectNameBox {
        border-bottom: #eceff8 solid 1px;

        h2,
        h3 {
            font-size: 18px;
            font-weight: 700;
            color: theme-color("grey");
            font-family: $font-opensans;
        }

        h3 {
            font-size: 14px;
        }
    }

    .questionInserted {
        ul {
            li {
                border-bottom: #eceff8 solid 1px;
                font-family: $font-opensans;
                font-size: 1rem;

                &:last-child {
                    border-bottom: none;
                }

                span {
                    cursor: pointer;
                    font-size: 1.5rem;
                }
            }
        }
    }
}

/*Survey Modal*/
.styles_simpleReactWeatherTemp__2PnUn,
.styles_simpleReactWeather__3uy4l {
    color: #08aded !important;
}
.styles_simpleReactWeather__3uy4l {
    display: flex;
    flex-flow: row;
}
.styles_simpleReactWeatherCity__zyECF {
    margin-bottom: 0 !important;
}
.styles_simpleReactWeatherTemp__2PnUn {
    margin: 12px 0 0 12px !important;
}

.font1rem {
    font-size: 1rem;
}
.font1p3rem {
    font-size: 1.3rem;
}

.green {
    color: theme-color("happy");
}
.red {
    color: theme-color("sad");
}

.orange {
    color: theme-color("meh");
}
.purple {
    color: theme-color("primary");
}
.grey {
    color: theme-color("grey");
}
.a-link {
    color: theme-color("secondary");
    text-decoration: underline;
}
.listingTable {
    .table {
        thead {
            th {
                position: relative;
                .sort-icon {
                    position: absolute;
                    top: 56%;
                    margin-left: 2px;
                }
            }
        }
    }
}
