.LoginPage {
    background:url(../assets/images/backdrop.jpg) center center no-repeat;
    background-color: #552682;
    background-size: cover;

    .loginContent {
        height: 100vh;
        z-index: 1000;
        position: relative;

        .loginBox {
            // max-width: 30vw;
            max-width: 450px;

            .loginLogo {
                padding-bottom: 1vw;

                img {
                    max-width: 75%;
                }
            }

            .card-body {
                border-radius: 3px;
                // height: 26vw;
                padding: 40px;
                box-shadow: 0 10px 20px #240144;

                .loginBtn {
                    // max-width: 85%;
                    margin-left: auto;
                    margin-right: auto;

                    .btn {
                        // padding: 0.85vw;
                        // font-size: 1vw;
                        text-transform: uppercase;
                        font-weight: 700;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.bg-animation {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}