@import "../mixins";

/*Dashboard*/
.dashboardContent {
    .card {
        border: none;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        // height: 100%;
        background-color: theme-color("white");

        .card-body {
            padding: 10px 20px;
            &.user-info {
                // min-height: 200px;
            }

            &.welcomeUserBlock {
                min-height: 110px;
            }

            .welcomeUserBox {
                height: 100%;

                >.d-md-flex {
                    height: 100%;
                }

                h1 {
                    font-weight: 900;
                    font-size: 1.875rem;
                    color: theme-color("primary");

                    span {
                        font-weight: 300;
                        font-size: 1.3rem;
                    }
                }

                .WeatherWidget {
                    position: absolute;
                    right: -30px;

                    @include for-1920-desktop {
                        top: -50px;
                    }

                    @include for-1600-desktop {
                        top: -30px;
                    }

                    img {
                        max-width: 100%;

                        @include for-1600-desktop {
                            max-width: 100px;
                        }
                    }
                }

                .welcomeTimeBox {
                    .welcomeCountryName {
                        color: theme-color("secondary");
                        font-size: 7px;
                        line-height: 10px;
                        font-weight: 700;
                        letter-spacing: 1.5px;
                    }

                    h1 {
                        font-size: 40px;
                        line-height: 55px;
                        font-weight: 300;
                        margin-bottom: 0;
                        color: theme-color("primary");
                    }

                
                }
            }
            .dateandtime {
                font-size: 13px;
                // line-height: 20px;
                color: theme-color("secondary");
                font-weight: 600;
                margin-bottom: 10px;
            }
            &.notifyUserBlock {
                background-color: theme-color("darkyellow");
                min-height: auto;
            }

            &.feedbackBlock {
                height: calc(100vh - 72px);
                overflow:auto;

                .feebackContent {
                    .questionBox {
                        .quesionCntr {
                            h2 {
                                background-image: linear-gradient(to right, #552681, #4f1f86, #46178b, #3b0e90, #2c0595);
                                font-size: 1rem;
                                color: theme-color("white");
                                font-weight: 500;
                                margin: 0;
                                border-radius: 100px;
                                padding: 20px 30px;
                                line-height: 1rem;
                            }

                            h3 {
                                font-size: 1rem;
                                color: theme-color("primary");
                                font-weight: 500;
                                margin-bottom: 0;
                            }

                            .answerSmily {
                                .radioBtnIcons {
                                    svg {
                                        cursor: pointer;
                                    }

                                    .nutralIcon {
                                        font-size: 36px;
                                        color: #E38C01;
                                    }

                                    .happyIcon {
                                        font-size: 42px;
                                        color: #37AA04;
                                    }

                                    .happySad {
                                        font-size: 42px;
                                        color: #C73D0A;
                                    }

                                    input[type='radio'] {
                                        display: none;

                                        +svg {
                                            opacity: 0.5;
                                        }

                                        &:checked+svg {
                                            opacity: 1;
                                        }
                                        &:hover + svg{
                                            opacity: 1;
                                        }
                                    }
                                }

                                .textareaAnswr {
                                    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
                                    overflow: hidden;
                                    border-radius: 15px;
                                    padding: 15px;
                                    position: relative;
                                    margin-bottom:10px;

                                    textarea {
                                        resize: none;
                                        border: none;
                                        font-size: 16px;
                                        height: 80px;
                                        width: 100%;

                                        &:focus {
                                            outline: none;
                                            border: none;
                                            box-shadow: none;
                                        }
                                    }

                                    // &::before {
                                    //     content: '';
                                    //     right: 40px;
                                    //     top: -13px;
                                    //     border-left: 15px solid transparent;
                                    //     border-right: 15px solid transparent;
                                    //     border-bottom: 15px solid white;
                                    //     position: absolute;
                                    //     z-index: 99;
                                    // }
                                }

                            }
                        }

                        .btn {
                            background: theme-color("darkyellow");
                            border-color: theme-color("darkyellow");
                            color: theme-color("grey");
                        }
                    }
                    .questionBox.gcomment{
                        .quesionCntr {
                            h2{
                                background-image: unset;
                                background: theme-color('lightgrey');
                            }
                        }
                    }

                }
            }

        }

        &.teamInfoDashboard {
            background: transparent;
            box-shadow: none;

            .card-body {
                .totalTeamCount {
                    .card-body {
                        min-height: auto;

                        .totalCountBx {
                            .teamMemberCount {
                                h2 {
                                    font-size: 3.75rem;
                                    font-weight: 700;
                                    // color: theme-color("primary");

                                    span {
                                        font-size: 11px;
                                        display: block;
                                        color: theme-color("grey");
                                        letter-spacing: 1px;
                                    }
                                }
                            }

                            .statusQuestion {
                                h2 {
                                    font-weight: 700;
                                    font-size: 1.575rem;
                                    @include above-1600-desktop {font-size:  1.875rem;}
                                    span{
                                        font-size: 18px; font-weight: 300;
                                        @include above-1600-desktop {font-size: 22px;}
                                    }
                                    svg{
                                        font-size: 12px; margin-top: -7px;
                                        @include above-1600-desktop {position: absolute; font-size: 14px;  left: -25px; top: 50%; margin-top: -7px;}
                                    }
                                }
                            }
                        }
                    }
                }
                .addQuetionBox{
                    .card-body{
                        min-height: 62px;
                        .addLink{
                            height: 100%; align-items: center;align-content: center;
                                a{width: 100%;align-self: center; color: theme-color("grey"); text-transform: uppercase;}
                        }
                    }
                }
            }
        }
    }
}

.canvas-container {
    height: 300px;
}

.alert:empty{
    display: none;
}

.count-box{

    h1 {
        font-size: 4.75rem;
        font-weight: 700;
        span {
            font-size: 11px;
            display: block;
            color: theme-color("grey");
            letter-spacing: 1px;
        }
    }
    h2 {
        font-size: 3rem;
        font-weight: 700;
        // color: theme-color("primary");
        
        span {
            font-size: 11px;
            display: block;
            color: theme-color("grey");
            letter-spacing: 1px;
        }
    }
}
.mh-300px{
    min-height: 300px;
}