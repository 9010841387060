// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  "primary": #552681,
  "secondary": #08ADED,
  "success": #08AD21,
  "danger": #f85032,
  "warning": #ffd700,
  "info": #00c9ff,
  "white":#ffffff,
  "darkyellow":#F3BA2E,
  "grey":#333333,
  "lightgrey":#666666,
  "borderColor":#CCCCCC,
  "black":#000,
  "happy":#68CD78,
  "sad":#DD896A,
  "meh":#F8D580,
);

$enable-gradients: true;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 100px;



