.cr-header {
  box-shadow: 0 0 10px 0px rgba(0,0,0,0.2);
  position: relative;
  z-index: 6;

  &__nav-right {
    flex-direction: row;
    justify-content: flex-end;
    margin-left: auto;
  }

  .logo {
    width: 100px;
    img{max-width: 100%; vertical-align: middle;}
  }

  .avatar {
    width: 35px;
    height: 35px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    background-color: theme-color("darkyellow");
    color:  theme-color("black");
    }
}