.form-group{
    margin-bottom: 2rem;
    label{
        font-size: 0.875rem;
        text-transform: uppercase;
        color: theme-color("lightgrey");
    }
    .form-control{
        height: auto;
        padding:0.6rem 0.85rem;
        font-size: 1rem;
        border-radius: 3px;
        color: rgba(0,0,0,0.75);
        &:focus{box-shadow: none; border-color:rgba(0,0,0,0.35);}
    }
}