.modal {
    .sideModal,
    .GenerateSurveyPage {
        max-width: 50%;
        margin: 0 0 0 auto;
        height: 100%;

        .modal-content {
            border: none;
            min-height: 100%;
            border-radius: 0;
        }
    }

    .modal-content {
        padding: 20px 30px;
    }

    .modal-header {
        position: relative;

        .close {
            margin: 0;
            padding: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: theme-color("black");
            color: theme-color("white");
            font-weight: 400;
            font-size: 21px;
            line-height: 30px;
            position: absolute;
            right: 0;
            top: 12px;
            opacity: 1;

            span {
                display: inline-block;
                font-size: 24px;
                vertical-align: top;
                line-height: 23px;
            }
        }
    }

    .modal-body {
        padding: 0;
    }

    .modal-footer {
        padding: 25px 0 5px 0;
        border-top-color: #E4E4E4;
        margin-top: 25px;
        justify-content: start
    }
}