.cr-sidebar {
  // @include media-breakpoint-down(md) {
  //   display: none;
  // }

  position: fixed;
  top: 0;
  bottom: 0;
  left: -$cr-sidebar-width;
  z-index: 5;
  width: $cr-sidebar-width;
  font-weight: 200;
  color: theme-color("white");;
  background-position: center center;
  background-size: cover;
  transition: left 0.1s ease-in;

  + .cr-content {
    transition: margin 0.1s ease-in;
  }

  &::after,
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }

  &::before {
    background: #000;
    opacity: 0.33;
  }

  &::after {
    z-index: 3;
    // sublime light
    background: theme-color("primary");
    opacity: 1;
  }

  &[data-image]:after {
    opacity: 0.77;
  }

  &__content {
    position: relative;
    z-index: 4;
    height: calc(100vh - 20px);
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-top: 60px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

  &__nav-item-collapse {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  &__nav-item-icon {
    // @include media-breakpoint-down(md) {
    //   padding: 0;
    //   margin: 0 auto;
    // }
    width: 27px;
    height: 27px;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }

  &--open {
    left: 0;
    transition: left 0.2s ease-in;

    + .cr-content {
      margin: 0 0 0 $cr-sidebar-width;
      transition: margin 0.2s ease-in;
    }
  }

  .nav {
    margin-top: 20px;

    .nav-item {
      margin: 0px 0px;
      border-radius: 4px;
      opacity: 1;
    }
    .logo-with-title-div {width: 100%; text-align: center;}
    .sidebar-icon-parent {padding: 15px 15px 5px;}
    .menu-name {padding-bottom: 10px; font-size: 15px;}
    .nav-link {
      // @include media-breakpoint-down(md) {
      //   padding: .5rem;
      // }
      color: theme-color("white");
      padding: 0;
      transition: all 0.2s ease-in;
      &:hover,&.active {
        color: theme-color("primary");
        background: #ECEFF8;
      }
    }
  }
}

.navigationIcon{ 
  top: 0; left: 0; z-index: 99; width: 100px;
  -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
  &.navCollaps{
    .menu-icon {
      margin-top: 0;
    }
  }
  .menu-icon {
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    position: relative;
    width: 30px;
    height: 22px;
    margin-left: auto;
    margin-right: auto; 
    margin-top: 25px;
    .icon {
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        position: absolute;
        height: 4px;
        width: 15px;
        top: 10px;
        background-color: #fff;
        border-radius: 4px;
        left: 0;
        &:before {
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            position: absolute;
            width: 30px;
            height: 4px;
            background-color: #fff;
            content: "";
            top: -9px;
            border-radius: 4px;
            left: 0;
        }
        &:after {
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            position: absolute;
            width: 25px;
            height: 4px;
            background-color: #fff;
            content: "";
            top: 9px;
            border-radius: 4px;
            left: 0;
        }
    }
    &.open {
        .icon {
            -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
            background: transparent;
            width: 26px;
            left: 50%;
            margin-left: -13px;
            &:before {
                -webkit-transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 5.5px);
                width: 100%;
                transform: rotateZ(45deg) scaleX(1.25) translate(6.5px, 5.5px);
                background-color: #2c3149;
            }
            &:after {
                -webkit-transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -5px);
                width: 100%;
                transform: rotateZ(-45deg) scaleX(1.25) translate(6px, -5px);
                background-color: #2c3149;
            }
        }
    }
    &:hover {
        cursor: pointer;
        .icon {
          width: 30px;
            &::before,
            &:after {
                width: 100%;
            }
        }
    }
}
}
