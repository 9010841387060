.nav{
    &.nav-tabs{
        border-bottom:5px solid #552681;
        .nav-link{
            &:hover,
            &:focus{
                border:1px solid transparent;
            }
            &.selected{
                background: #552681;
                color: white;
                box-shadow: none;
            }
        }
    }
}